import { GenericOAuth2 } from '@capacitor-community/generic-oauth2'
import * as oauthConfigs from '@/utils/oauthConfigs'
import { Capacitor } from '@capacitor/core'
import { loadUrl } from '@/utils/helpers'

// Access token object is different per platform for some reason
function getAccessToken(res) {
  if (res?.authorization_response?.error) {
    throw new Error(res?.authorization_response?.error)
  }
  if (res.access_token) return res.access_token
  if (res.authorization_response) {
    return res.authorization_response.access_token ?? res.authorization_response['#access_token']
  }
  if (res.access_token_response) {
    return res.access_token_response.access_token ?? res.access_token_response['#access_token']
  }
  throw new Error('Access token not found')
}

// NOTE this code is never run by extensions
export default {
  async handleGoogleAuth({ dispatch, getters, rootGetters }, opts) {
    const resp = await GenericOAuth2.authenticate(oauthConfigs.google)

    const deviceInfo = opts?.deviceInfo ?? rootGetters['device/all']
    const consent = opts?.consent ?? getters.onboardingConsent

    const tokens = await this.$api.auth.ssoGoogleAuth({
      accessToken: getAccessToken(resp),
      deviceInfo,
      consent,
    }, opts?.requestOpts)

    if (opts?.link) return true
    if (opts?.external) return tokens
    return dispatch('handleAuthTokens', tokens)
  },
  async handleFacebookAuth({ dispatch, getters, rootGetters }, opts) {
    const resp = await GenericOAuth2.authenticate(oauthConfigs.facebook)

    const deviceInfo = opts?.deviceInfo ?? rootGetters['device/all']
    const consent = opts?.consent ?? getters.onboardingConsent

    const accessToken = getAccessToken(resp)
    const tokens = await this.$api.auth.ssoFacebookAuth(
      { accessToken, deviceInfo, consent },
      opts?.requestOpts,
    )

    if (opts?.link) return true
    if (opts?.external) return tokens
    return dispatch('handleAuthTokens', tokens)
  },
  async handleAppleWebAuth() {
    // never runs on native or extensions, because imports an external source
    if (!window.AppleID) {
      await loadUrl('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js')
    }

    window.AppleID.auth.init({
      clientId: oauthConfigs.apple.appId,
      scope: oauthConfigs.apple.scope,
      redirectURI: oauthConfigs.apple.web.redirectUrl,
      usePopup: true,
    })

    const { authorization } = await window.AppleID.auth.signIn()
    return authorization
  },
  async handleAppleAuth({ dispatch, getters, rootGetters }, opts) {
    let resp

    // use web SDK if we are on the web
    if (Capacitor.getPlatform() === 'web') {
      resp = await dispatch('handleAppleWebAuth', opts)
    } else {
      // otherwise use the native SDK
      resp = await GenericOAuth2.authenticate(oauthConfigs.apple)
    }

    const deviceInfo = opts?.deviceInfo ?? rootGetters['device/all']
    const consent = opts?.consent ?? getters.onboardingConsent

    const tokens = await this.$api.auth.ssoAppleAuth({
      idToken: resp.id_token,
      deviceInfo,
      consent,
    }, opts?.requestOpts)

    if (opts?.link) return true
    if (opts?.external) return tokens
    return dispatch('handleAuthTokens', tokens)
  },
  async getSocialConnections() {
    return this.$api.auth.ssoGet()
  },
  async unlinkSocialConnection(_, provider) {
    return this.$api.auth.ssoUnlink({ provider })
  },
}
