<template>
  <DropdownHybrid
    ref="dropdown"
    :sheet-title="$t('page.bookmarks.layout.sheet')"
    :menu-props="{left: true, minWidth: 240}"
  >
    <template #activator="{ on, attrs }">
      <BrandButton
        variant="secondary"
        color="neutral25 neutral900--text"
        :class="$vnode.data.staticClass"
        v-bind="{...attrs, ...btnProps}"
        v-on="on"
      >
        <component :is="getStyleIcon(listStyle)" class="text-h5" />
        <span class="ml-3 d-none d-md-inline" v-text="$t(getStyleText(listStyle))" />
      </BrandButton>
    </template>

    <v-list color="neutral25" class="py-3">
      <v-list-item v-for="option in options" :key="option.key" @click="onSelect(option.value)">
        <v-list-item-icon class="mr-3">
          <component :is="option.icon" class="text-h5" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="neutral900--text font-weight-medium" v-text="$t(option.text)" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </DropdownHybrid>
</template>

<script>
import {
  PhListBullets, PhList, PhSquaresFour, PhRows, PhGridFour,
} from 'phosphor-vue'
import { ITEM_LAYOUT_STYLE } from '@/utils/constants'

const ICON_CARDS_DESKTOP = PhSquaresFour
const ICON_TILES_DESKTOP = PhGridFour
const ICONS = {
  [ITEM_LAYOUT_STYLE.cards]: PhRows,
  [ITEM_LAYOUT_STYLE.tiles]: PhRows,
  [ITEM_LAYOUT_STYLE.list]: PhListBullets,
  [ITEM_LAYOUT_STYLE.headlines]: PhList,
}

const TEXTS = {
  [ITEM_LAYOUT_STYLE.cards]: 'component.bookmarks.style.list.cards',
  [ITEM_LAYOUT_STYLE.tiles]: 'component.bookmarks.style.list.tiles',
  [ITEM_LAYOUT_STYLE.list]: 'component.bookmarks.style.list.list',
  [ITEM_LAYOUT_STYLE.headlines]: 'component.bookmarks.style.list.headlines',
}

export default {
  inheritAttrs: false,
  props: {
    listStyle: {
      type: String,
      required: true,
    },
  },
  computed: {
    btnProps() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return { rounded: true, xLarge: true }
      }

      return { height: 45, width: 45, fab: true }
    },
    options() {
      return Object.keys(ITEM_LAYOUT_STYLE).map(value => ({
        icon: this.getStyleIcon(value),
        text: this.getStyleText(value),
        value,
      }))
    },
  },
  methods: {
    onSelect(val) {
      this.$refs.dropdown?.close()
      this.$emit('set', val)
    },
    getStyleIcon(style) {
      const { smAndUp } = this.$vuetify.breakpoint
      if (style === ITEM_LAYOUT_STYLE.cards && smAndUp) {
        return ICON_CARDS_DESKTOP
      }

      if (style === ITEM_LAYOUT_STYLE.tiles && smAndUp) {
        return ICON_TILES_DESKTOP
      }

      return ICONS[style]
    },
    getStyleText(style) {
      return TEXTS[style]
    },
  },
}
</script>
