export const google = {
  authorizationBaseUrl: 'https://accounts.google.com/o/oauth2/auth',
  accessTokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
  scope: 'email profile',
  logsEnabled: process.env.NODE_ENV !== 'production',
  resourceUrl: 'https://www.googleapis.com/userinfo/v2/me',
  web: {
    appId: '831644616885-u8r1upf9rdcodij2jfrbf8fpk8bcvqrb.apps.googleusercontent.com',
    responseType: 'token', // implicit flow
    accessTokenEndpoint: '', // clear the tokenEndpoint as we know that implicit flow gets the accessToken from the authorizationRequest
    redirectUrl: `${CONFIG.appUrl}/auth/sso/return`,
    sendCacheControlHeader: false,
    // windowOptions: 'width=600,height=600,left=0,top=0',
    // windowTarget: '_self',
  },
  android: {
    appId: '831644616885-q9m8hqtbmq3u51nqf8t1upg3aa2iefjl.apps.googleusercontent.com',
    responseType: 'code', // if you configured a android app in google dev console the value must be "code"
    redirectUrl: 'com.rubra.appclient:/', // package name from google dev console
  },
  ios: {
    appId: '831644616885-tjev732eh3k2usebgcqtvbjnd5kkc2rf.apps.googleusercontent.com',
    responseType: 'code', // if you configured a ios app in google dev console the value must be "code"
    redirectUrl: 'com.googleusercontent.apps.831644616885-tjev732eh3k2usebgcqtvbjnd5kkc2rf:/', // custom ios URL scheme
  },
}

export const apple = {
  appId: 'com.rubra.login-apple',
  authorizationBaseUrl: 'https://appleid.apple.com/auth/authorize',
  scope: 'name email',
  logsEnabled: process.env.NODE_ENV !== 'production',
  web: {
    responseType: 'code',
    redirectUrl: `${CONFIG.appUrl}/auth/sso/return`,
    additionalParameters: {
      response_mode: 'form_post',
    },
  },
  ios: {
    siwaUseScope: true,
    scope: 'name email',
  },
}

// NOTE not in use currently
const fbApiVersion = 'v15.0' // needs to match with backend
export const facebook = {
  appId: '665213301930274',
  authorizationBaseUrl: `https://www.facebook.com/${fbApiVersion}/dialog/oauth`,
  scope: 'email public_profile',
  logsEnabled: process.env.NODE_ENV !== 'production',
  web: {
    responseType: 'token',
    redirectUrl: `${CONFIG.appUrl}/auth/sso/return`,
    additionalParameters: {
      scope: 'email,public_profile',
    },
  },
  android: {
    customHandlerClass: 'com.rubra.appclient.SSOFacebookHandler',
  },
  ios: {
    customHandlerClass: 'App.SSOFacebookHandler',
  },
}
