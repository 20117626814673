import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

import store from '@/store'
import { awaitGetterUpdate } from '@/utils/store/store-utils'

import auth from '@/routes/auth'
import bookmarks from '@/routes/bookmarks'
import collections from '@/routes/collections'
import account from '@/routes/account'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/bookmarks',
  },
  ...(BUILD_TARGET === 'extension' ? [
    {
      path: '/index.html',
      redirect: '/bookmarks',
    },
    {
      path: '/popup.html',
      redirect: '/bookmarks',
    },
  ] : []),
  // extension
  {
    path: '/extension/auth/sso/:provider',
    component: () => import(/* webpackChunkName: "auth-ext-setup" */ '@/views/Auth/ExtSetup.vue'),
    meta: {
      layout: 'fullscreen',
      skipOnboarding: true,
    },
  },
  ...bookmarks,
  ...collections,
  ...account,
  ...auth,
]

const router = new VueRouter({
  mode: BUILD_TARGET === 'extension' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: async (to, from, savedPosition) => {
    let scrollTo = 0

    if (!to.meta.noScrollPreservation) {
      if (to.hash) {
        scrollTo = to.hash
      } else if (savedPosition) {
        scrollTo = savedPosition.y
      }
    }

    // await sleep(300)

    return goTo(scrollTo)
  },
})

// go to a route with back function if available
router.backTo = to => {
  // check if last route is equal to the route we want to go back to
  // if it is, use back instead of replace
  const { lastRoute } = router
  if (lastRoute) {
    if (typeof to === 'string' && lastRoute.path === to) return router.back()
    if (typeof to === 'object' && lastRoute.path === to.path && lastRoute.query === to.query) return router.back()
  }

  console.log('replacing route', to)
  return router.replace(to)
}

router.beforeEach(async (to, from, next) => {
  // avoid endless error loop
  if (to.name === '404') {
    next()
    return
  }

  // user hasnt completed onboarding
  if (!to.meta.skipOnboarding) {
    let onboardingConsent = store.getters['auth/onboardingConsent']
    if (onboardingConsent === undefined) {
      try {
        onboardingConsent = await awaitGetterUpdate(store, 'auth/onboardingConsent')
      } catch (error) {
        onboardingConsent = null
      }
    }

    if (!onboardingConsent?.ConsentTerms && to.path !== '/onboarding') {
      const redirectUri = to.fullPath.startsWith('/auth') ? undefined : to.fullPath

      next({
        path: '/onboarding',
        query: { authRedirect: redirectUri, ...to.query },
      })
      return
    }
  }

  // auth check
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let userId = store.getters['auth/userId']

    // auth hasn't been fetched yet
    if (userId === undefined) {
      try {
        userId = await awaitGetterUpdate(store, 'auth/userId')
      } catch (error) {
        userId = null
      }
    }

    // if (still) no auth, redirect to login page.
    if (!userId) {
      next({
        path: '/auth',
        query: { authRedirect: to.fullPath, ...to.query },
      })

      return
    }

    // Bulk action
    const bulkActionsCloseConditions = [
      { from: /^\/bookmarks$/, exclude: [/^\/bookmarks\/bulk\/add-collections$/, /^\/bookmarks\/bulk\/add-tags$/, /^\/bookmarks\/bulk\/remove-tags$/] },
      { from: /^\/collections\/[^/]+$/, exclude: [/^\/collections\/[^/]+\/bulk\/add-tags$/, /^\/collections\/[^/]+\/bulk\/remove-tags$/, /^\/collections\/[^/]+\/bulk\/edit$/, /^\/collections\/[^/]+\/bulk\/add-collections$/] }, // Dynamic collectionId route with dynamic exclude
      { from: /^\/collections$/, exclude: [/^\/collections\/bulk\/invite$/, /^\/collections\/bulk\/edit-share-link$/] },
      { from: /^\/bookmarks\/deleted$/, exclude: [] },
    ]

    if (store.getters['bulkActions/isActive']) {
      bulkActionsCloseConditions.forEach(condition => {
        const isFromMatched = condition.from.test(from.path)
        const isExcluded = condition.exclude.some(regex => regex.test(to.path))

        if (isFromMatched && !isExcluded) {
          store.commit('bulkActions/closeActions')
        }
      })
    }
  }

  // NOTE not in use currently
  // requires full account check
  // if (to.matched.some(record => record.meta.requiresFullAccount)) {
  //   //
  // }

  // TODO some form of way to prevent people from going back to auth route if logged in
  // (only on mobile & extension, otherwise tab is stuck)
  // actually the extension doesn't even have forward/backwards buttons so it shouldn't happen

  next()
})

// keep track of last route
router.afterEach((_, from) => {
  router.lastRoute = from
})

export default router
