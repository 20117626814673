import Preferences from '@/plugins/preferences'

export default [
  {
    path: '/onboarding',
    component: () => import(/* webpackChunkName: "auth-onboarding" */ '@/views/Auth/Onboarding.vue'),
    meta: {
      layout: 'fullscreen',
      transition: {
        back: 'scale-transition',
        forward: 'fade-transition',
      },
    },
  },
  {
    path: '/auth/offboarding',
    component: () => import(
      /* webpackChunkName: "auth-offboarding" */
      '@/views/Auth/Offboarding.vue'
    ),
    meta: {
      layout: 'focus',
      transition: 't-fade',
      layoutProps: { backButton: false },
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/auth',
    async beforeEnter(to, from, next) {
      next(await Preferences.get('hasAccount') ? '/auth/login' : '/auth/signup')
    },
  },
  {
    path: '/auth/signup',
    component: () => import(/* webpackChunkName: "auth-signup" */ '@/views/Auth/Signup.vue'),
    meta: {
      transition: {
        parallel: 't-none',
        forward: 't-expand',
      },
      layout: 'focus',
      layoutProps: { backButton: false },
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/auth/login',
    component: () => import(/* webpackChunkName: "auth-login" */ '@/views/Auth/Login.vue'),
    meta: {
      transition: {
        parallel: 't-none',
      },
      layout: 'focus',
      layoutProps: { backButton: false },
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
    },
  },
  {
    path: '/auth/login/forgot',
    component: () => import(/* webpackChunkName: "auth-forgot" */ '@/views/Auth/Forgot.vue'),
    meta: {
      layout: 'focus',
      layoutProps: { backButton: false },
      layoutTransition: 't-fade',
      systemBar: {
        color: 'neutral0',
      },
      transition: {
        parallel: 'scale-transition',
        back: 'scale-transition',
        forward: 'fade-transition',
      },
    },
  },
  {
    path: '/auth/user/recovery',
    component: () => import(/* webpackChunkName: "auth-recovery" */ '@/views/Auth/Recovery.vue'),
    meta: {
      layout: 'focus',
      skipOnboarding: true,
    },
  },
  {
    path: '/auth/mfa/revoke',
    component: () => import(/* webpackChunkName: "auth-revoke-mfa" */ '@/views/Auth/RevokeMfa.vue'),
    meta: {
      layout: 'focus',
    },
  },
  {
    path: '/auth/logout',
    component: () => import(/* webpackChunkName: "auth-logout" */ '@/views/Auth/Logout.vue'),
  },
]
